import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {setUser} from '@sentry/angular';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AuthLoginModel, AuthLoginType} from '../types/auth-login.model';
import {AuthRoleModel} from './auth-role.model';
import {RolesEnum} from './roles.enum';

@Injectable()
export class AuthService {
  #authSubject: BehaviorSubject<AuthLoginModel | null> = new BehaviorSubject<AuthLoginModel | null>(
    localStorage.getItem('xp:auth')
      ? new AuthLoginModel().fromLocalStorage(JSON.parse(localStorage.getItem('xp:auth')))
      : null,
  );

  #roleSubject: BehaviorSubject<AuthRoleModel | null> = new BehaviorSubject<AuthRoleModel | null>(
    localStorage.getItem('xp:role')
      ? new AuthRoleModel(JSON.parse(localStorage.getItem('xp:role')))
      : null,
  );

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  isLogin(): boolean {
    return !!this.auth && !!this.role;
  }

  set auth(value: AuthLoginModel | null) {
    if (value) {
      localStorage.setItem('xp:auth', JSON.stringify(value));
    } else {
      localStorage.removeItem('xp:auth');
    }
    this.#authSubject.next(value);
  }

  get auth(): AuthLoginModel | null {
    return this.#authSubject.value;
  }

  set role(value: AuthRoleModel | null) {
    if (value) {
      localStorage.setItem('xp:role', JSON.stringify(value));
    } else {
      localStorage.removeItem('xp:role');
    }
    this.#roleSubject.next(value);
  }

  get role(): AuthRoleModel | null {
    return this.#roleSubject.value;
  }

  signIn(request: {username: string; password: string}): Observable<AuthRoleModel> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const body = new URLSearchParams({
      ...request,
      grant_type: 'password',
    });

    return this.http.post<AuthLoginType>('/oauth2/token', body.toString(), {headers}).pipe(
      map(response => {
        const authLoginModel: AuthLoginModel = new AuthLoginModel().convert(response);
        this.auth = authLoginModel;

        return authLoginModel;
      }),
      switchMap(model =>
        this.getRoleByUsername(model.username).pipe(
          switchMap(response => {
            if (response.roleNo === RolesEnum.ADMIN) {
              setUser({email: request.username});
              this.role = response;
              return of(response);
            } else {
              this.auth = null;
              this.role = null;
              return throwError(() => 'current user role is not authorized');
            }
          }),
        ),
      ),
    );
  }

  refreshToken(refreshToken: string): Observable<unknown> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const body = new URLSearchParams({
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    });

    return this.http.post<AuthLoginType>('/oauth2/token', body.toString(), {headers}).pipe(
      map(response => {
        const authLoginModel: AuthLoginModel = new AuthLoginModel().convert(response);
        this.auth = authLoginModel;

        return authLoginModel;
      }),
    );
  }

  signOut(): Promise<boolean> {
    this.auth = null;
    this.role = null;
    setUser(null);
    return this.router.navigateByUrl('/sign-in', {replaceUrl: true});
  }

  getRoleByUsername(username: string): Observable<AuthRoleModel> {
    return this.http
      .get<AuthRoleModel>(`/user/detail/${username}`)
      .pipe(map(response => new AuthRoleModel(response)));
  }
}
